.greetings-navbar {
  height: 47px;
  margin: 10px;
  position: relative;

  span.greetings-text {
    text-align: center;
    margin-top: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: 6rem;
  }

  button.signout-button {
    min-width: 150px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto';
    letter-spacing: 1.1px;
    padding: 14px 0;
    text-align: center;
    background-color: #f90;
    float: right;
  }

}