@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import './variables.scss';

// Styles
body {
  font-family: 'Roboto', sans-serif;
  color: $black;
  background-color: $white;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  overflow-x: hidden;
}

.padding-wrapper {
  padding: 0 15px;
}

// Media Queries
@media (min-width: 768px) {
  .container {
    max-width: 1530px;
  }
}
















// Importing all the scss files at the END
@import "./imports.scss";