.ReactTable {
  border: 1px solid #e6e1e1;

  .rt-thead {
    height: 61px;

    .rt-tr {
      color: $light-navy;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: 0.5px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);

      .rt-th {
        text-align: center;
        line-height: 61px;
        padding: 0;
      }

      .rt-th:not(:last-child) {
        border-right: 1px solid #e6e1e1;
      }
    }
  }

  .rt-tbody {
    .rt-tr-group {
      height: 50px;

      .rt-td:first-child {
        color: $black;
        font-weight: 500;
      }

      .rt-td:not(:last-child) {
        border-right: 1px solid #e6e1e1;
      }

      .rt-td-column {
        position: relative;
        padding: 0;
        line-height: 50px;
        color: $greyish-brown;
        font-weight: 400;

        .rt-td-column-data.align-left {
          padding-left: 30px;
        }

        span.userName,
        span.rt-td-column-value {
          font-weight: 500;
          color: $black;
        }

        .rt-td-column-data.puck-status {
          span.rt-td-column-value {
            letter-spacing: 0.4px;
            margin-left: 9px;
            font-size: 15px;
          }

          span.active::before {
            content: "";
            width: 16px;
            height: 16px;
            background-image: linear-gradient(to bottom, #b8df00, #82b400);
            display: inline-block;
            border-radius: 50%;
            vertical-align: middle;
          }

          span.inactive::before {
            content: "";
            width: 16px;
            height: 16px;
            background-image: linear-gradient(to bottom, #fc9921, #da7800);
            display: inline-block;
            border-radius: 50%;
            vertical-align: middle;
          }
        }
      }
    }

    .rt-tr {
      &:hover {
        background-color: #def6ff !important;
      }
    }
  }
}

.arrowClick {
  width: 80px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;

  span {
    border: solid #adadad;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &:hover {
    span {
      border: solid #2fb3e8;
      border-width: 0 3px 3px 0;
    }
  }
}