.rt-noData {
  background-color: transparent !important;
  margin-top: 12px;

  .no-data-found {
    p {
      color: #f98320;
      font-size: 24px;
      letter-spacing: 0.8px;
    }
  }
}