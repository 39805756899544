.device-summary-wrapper {
  margin-top: 36px;

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .category {
    color: $blue;
    font-size: 20px;
    margin-bottom: 6px;
  }

  .device-name {
    font-size: 18px;
    margin-bottom: 6px;
  }

  span.active::before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: linear-gradient(to bottom, #b8df00, #82b400);
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }

  span.inactive::before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: linear-gradient(to bottom, #fc9921, #da7800);
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }

  .device-summary-tabs {
    height: 47px;
    border-radius: 7px;
    background-color: $nav;
    position: relative;
    margin-bottom: 30px;

    .device-summary-span {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 16px;
      margin: auto;
      text-align: center;
      color: $white;
    }
  }

  .image-col {
    vertical-align: middle;
    text-align: center;

    .device-summary-image {
      width: 75%;
      height: auto;
    }
  }

  .horizontal-line {
    margin-top: 30px;
  }

  .device-summary-details {
    margin-left: 1.2rem;

    .holder {
      padding-bottom: 1rem;

      .field {
        color: $greyish-brown;
        margin-bottom: 8px;
      }

      .value {
        font-weight: bold;
        color: $black;
      }

    }
  }
}

.user-info-spinner-wrapper {
  width: 100%;
  height: 266px;
  background-color: $third-header;
  position: relative;
}