.server-error-v1 {
  text-align: center;
  height: 800px;
  padding-top: 150px;
  border: 1px solid #e6e1e1;

  h2 {
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.9px;
    color: $greyish-brown;
  }

  img {
    height: 146px;
    width: 146px;
    margin: 31px 0;
  }

  button {
    width: 125px;
    height: 42px;
    border-radius: 5px;
    background-color: $dark-sky-blue;
    color: $white;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-top: 16px;
  }
}

.server-error-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e1e1;

  p {
    margin: auto;
    color: #f98320;
    font-size: 24px;
    letter-spacing: 0.8px;
  }
}