.react-select-container {
  width: 20%;

  .react-select__menu-list {
    padding: 0;
    margin-top: -2px;
    border-radius: 5px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__control {
    box-shadow: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: rgba(255, 255, 255, 0.1);

    .css-xp4uvy {
      color: $white;
      font-weight: 400;
    }

    .material-icons {
      color: $white;
    }
  }

  .react-select__control:hover {
    border-color: $white;
  }

  .react-select__control--is-focused {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: $darkgrey;
  }

  .react-select__menu {
    margin-top: 3px;

    .react-select__menu-list {
      .react-select__option {
        min-height: 40px;
        padding: 10px 12px;
        border-bottom: none;
      }

      .react-select__option--is-focused {
        background-color: #415f6b;
      }

      .react-select__option--is-selected::after {
        float: right;
        background-image: url('../img/listcheck.png');
        background-size: 10px 10px;
        display: block;
        margin-top: 5px;
        width: 10px;
        height: 10px;
        content: "";

      }
    }
  }
}

.pagination-select {
  width: 5%;

  .react-select__control {
    height: 6px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);

    .css-xp4uvy {
      color: #5f5f5f;
      font-weight: 400;
    }

    .material-icons {
      color: #5f5f5f;
    }
  }

  .react-select__control--is-focused {
    background-color: $whisper;
  }

  .react-select__menu {
    margin-top: -7px;

    .react-select__menu-list {
      .react-select__option {
        background-color: $whitesmoke;
        color: #5f5f5f;
        height: 40px;
        border-bottom: 1px solid #b7bfc9;
      }

      .react-select__option--is-focused {
        background-color: $light-gray;
      }
    }
  }
}