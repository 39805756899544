.react-performance-tab {
  .performance-charts-section-row {
    background-color: $third-header;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
    margin: auto;
    margin-bottom: 2rem;

    .upload-speed-header,
    .download-speed-header,
    .bandwidth-demand {
      font-size: 16px;
      color: $white;
      font-weight: 500;
      text-align: center;
    }

    .line-chart-section {
      border-radius: 10px;
      background-color: #2b2b2b;
    }

    canvas {
      padding: 10px;
    }
  }

  .average-internet-speed-table {
    .rt-tr-group {
      height: 80px !important;
    }

    .rt-td-column {
      line-height: normal !important;
      display: flex;
      align-items: center;
    }

    .rt-td-column-value {
      margin: 0;
    }

    p {
      padding: 2px;
      letter-spacing: 0.4px;

      &.average {
        color: $black;
        font-weight: 500;
      }
    }
  }

  .days-filter {
    margin: 25px 0 30px;

    .btn {
      width: 256px;
      height: 50px;
      color: $blue;
      background-color: #f5fcff;
      border: 1px solid $blue;
      border-radius: 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 0.94;
      letter-spacing: 0.1px;
      padding: 0;
      margin: 0;
      line-height: 50px;
      -webkit-appearance: none !important;

      input[type="radio"] {
        display: none;
      }

      &:focus {
        box-shadow: none;
      }

      &.active {
        color: $white;
        background-color: $blue;
      }
    }

    .btn-toolbar {
      justify-content: center;
    }
  }

  .average-devices-latency-table {
    margin-bottom: 50px;

    .rt-thead {
      z-index: $z-index-latency-thead;
    }

    .rt-tr-group {
      height: 60px !important;
    }

    .rt-td-column {
      line-height: normal !important;
      display: flex;
      align-items: center;

      &.content-center {
        justify-content: center;
      }
    }

    .rt-td-column-value {
      margin: 0;
    }

    p {
      padding: 2px;
      letter-spacing: 0.4px;

      &.average {
        color: $black;
        font-weight: 500;
      }
    }
  }

  .total-number-of-device {
    position: relative;
    top: -22px;
    letter-spacing: 0.1px;
    font-weight: 500;
    color: $greyish-brown;
  }

  .reload-svg {
    bottom: auto;
    top: 400px;
  }
}