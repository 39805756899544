.users-count-container{
  padding: 30px;
  background-color: $third-header;
  .users-count-wrapper{
    float: left;
    img{
      float: left;
      width: 26px;
      height: 26px;
    }
    .users-count-text-holder{
      float: left;
      margin-left: 8px;
      .text{
        margin: 0;
        letter-spacing: 0.4px;
        color: $light-gray;
        font-size: 14px;
        line-height: 26px;
        font-weight: 400;
      }
      .value{
        margin: 0;
        letter-spacing: 0.9px;
        color: $white;
        font-size: 33px;
        font-weight: 400;
      }
    }
  }
  .seperator{
    float: left;
    border-right: 1px dashed $white;
    width: 1%;
    height: 65px;
    margin: 0 30px;
  }
  .search-col {
    .search-component{
      display: flex;
      margin-top:0.9rem;
      .react-select-container{
        width:20%;
        .react-select__menu-list{
          padding: 0;
          margin-top: -2px;
          border-radius: 5px;
        }
        .react-select__indicator-separator{
          display: none;
        }
        .react-select__control{
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          background-color: rgba(255, 255, 255, 0.1);
          cursor: pointer;
          .css-xp4uvy {
            color: $white;
            font-weight: 400;
          }
          .material-icons {
            color: $white;
          }
        }
        .react-select__control--is-focused{
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
      .search-container{
        width:80%;
        display: flex;
        .input-field {
          width:90%;
        }
        input:focus {
          background-color: #f3f1d5;
        }
        .button-field {
          width:10%;
          background-image:  linear-gradient(to bottom, #565529, #4c4a2a);
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;
          cursor: pointer;
          .material-icons {
            position: absolute;
            top:8px;
            left:1.1rem;
            color:$white;
          }
        }
        button:focus {
          background-image: linear-gradient(to bottom, #d5c922, #b09d25);
        }
        .search-clear {
          display: flex;
          height: 23px;
          width: 23px;
          justify-content: center;
          position: absolute;
          top: 23px;
          right: 82px;
          align-items: center;
          background: #cecece;
          border-radius: 50%;
          color: $white;
          z-index: $z-index-search-clear;
          cursor: pointer;
          .material-icons {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}


.main-table{
  .rt-th:last-child{
    text-align: left !important;
    padding-left: 30px !important;
  }
}