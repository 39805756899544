.header-wrapper{
  background-color: $top-header;
}
.header{
  position: relative;
  max-width: 1500px;
  margin: auto;
}
.logo-wrapper{
  width: 100%;
  height: 80px;
  position: relative;
  .disco-logo{
    position: absolute;
    top: 14px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .asurion-logo{
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .menu-icon-open{
    height: 50px;
    width: 50px;
    position: absolute;
    left: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
}
.menu{
  position: fixed;
  top: 67px;
  width: 254px;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: $z-index-header-menu;
  .menu-list{
    list-style: none;
    color: $white;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.3px;
    margin-top: 80px;
    padding: 0;
    text-transform: uppercase;
    li{
      cursor: pointer;
      padding-left: 37px;
      &:hover, &.selected{
        background-color: $blue;
      }
    }
  }
  .menu-icon-close{
    height: 50px;
    width: 50px;
    position: relative;
    top: 15px;
    left: 30px;
    cursor: pointer;
  }
}