// Text Colors
$white: #fff;
$black: #000;
$light-gray: #d6d6d6;
$greyish-brown: #595959;
$baby-blue: #a5e3ff;
$blue: #2898c6;
$light-navy: #134d97;

// Background Color
$top-header: #20252b;
$sub-header: #2898c6;
$third-header: #3b4047;
$user-info-panel: #f0f0f0;
$nav: #244063;
$dark-slate-blue: #244063;
$dark-sky-blue: #4caad1;
$zambezi: #5f5f5f;
$darkgrey: #a7a7a7;
$whitesmoke: #f4f4f4;
$whisper: #e4e4e4;

// Z-index
$z-index-latency-thead: 9;
$z-index-search-clear: 99;
$z-index-header-menu: 999;
$z-index-spinner: 99999;