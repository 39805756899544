.user-overview-table {
  .rt-resizable-header-content {
    font-weight: 500;
  }
}

.user-info-wrapper {
  background-color: $user-info-panel;
}

.tabs {
  margin-top: 36px;

  .react-tabs__tab {
    width: 33%;
    background: rgba(126, 132, 142, 0.9);
    color: $white;
    border-radius: 5px 5px 0 0;
    border: 0;
    padding: 15px 0;
    margin: 0;
    font-size: 17px;
    letter-spacing: 0.5px;
    text-align: center;

    &:first-child {
      margin-right: 0.5%;
    }

    &:last-child {
      margin-left: 0.5%;
    }
  }

  .react-tabs__tab--selected {
    background-color: $dark-slate-blue;
  }

  .react-tabs__tab-panel--selected {
    margin-top: -10px;
  }
}

.user-charts-wrapper {
  height: 266px;
}

.user-info {
  padding: 39px 15px;
  letter-spacing: 0.2px;

  h4 {
    color: $light-navy;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .holder {
    border-bottom: 1px dotted rgba(106, 106, 106, 0.3);
    font-size: 18px;
    margin-bottom: 12px;

    .field {
      color: $greyish-brown;
      margin-bottom: 10px;
      font-weight: 400;
    }

    .value {
      color: $black;
      margin-bottom: 15px;
      font-weight: 500;
    }

    span.active::before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: linear-gradient(to bottom, #b8df00, #82b400);
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 10px;
    }

    span.inactive::before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: linear-gradient(to bottom, #fc9921, #da7800);
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.user-info-charts-section {
  padding-top: 10px;
  padding-bottom: 1.2rem;

  .g-container {
    pointer-events: all;
  }

  .pie-chart-section {
    padding: 2rem;

    canvas {
      margin-left: 25px;
      font-family: 'Roboto';
    }

    tspan {
      .tspan-text {
        margin-top: 20px;
      }
    }

    .tooltip {
      background: #eee;
      box-shadow: 0 0 5px #999999;
      color: #333;
      font-size: 12px;
      left: 50px;
      padding: 10px;
      position: absolute;
      text-align: center;
      top: 95px;
      width: 80px;
      z-index: 10;
      display: block;
      opacity: 1;
    }
  }

  background-color: $third-header;
  color: $light-gray;
  margin:auto;

  .progress-bar-section {
    padding-left: 4rem;
    margin-top: 20px;

    .right-align {
      text-align: right;
    }

    .row {
      height: 25%;
    }

    .active-inactive-count {
      .inactive-devices {
        margin-left: -3rem;
      }

      img {
        width: 20px;
        height: 20px;
        margin: 0 8px;
      }

      .device-count {
        margin-left: 22px;
        margin-top: 5px;
        font-size: 24px;
      }

      span {
        font-size: 13px;
      }
    }

    .last-updated-date {
      text-align: right;
      color: $baby-blue;
      font-weight: 300;
      font-size: 14px;
    }

    .device-count {
      color: $white;
    }

    .smart-home-device {
      .progress-bar {
        background-color: #0073a3
      }
    }

    .entertainment {
      .progress-bar {
        background-color: #34c9d5
      }
    }

    .computers-phones-printers {
      .progress-bar {
        background-color: #07716a
      }
    }

    .home-security {
      .progress-bar {
        background-color: #62a269
      }
    }

    .networking {
      .progress-bar {
        background-color: #747f1a
      }
    }

    .other {
      .progress-bar {
        background-color: #cccd5d
      }
    }


    .progress {
      height: 8px;
      margin-top: 12px;
      border-radius: 0;
      box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.39);
      background-color: #282b2e;
    }
  }
}

.user-overview-pagination {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .pageNumber {
    padding-left: 10px;
    font-weight: 400;
    color: $greyish-brown;
  }

  .react-paginate {
    width: 65%;
  }

  .rows-per-page {
    padding-right: 3px;
    width: 12%;
    color: $greyish-brown;
  }

  .css-d8oujb {
    display: none;
  }

  .pagination-select {
    padding-right: 10px;
    width: 8%;
  }
}