.subheader-wrapper{
  height: 50px;
  background-color: $sub-header;
  color: $white;
  p{
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    margin: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight:500;
  }
  .arrow-left{
    width: 34px;
    height: 34px;
    position: absolute;
    top: 6px;
    left: 50px;
    cursor: pointer;
  }
}