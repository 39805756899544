@mixin style1 {
  color: $white;
  cursor: default;
  background-color: $blue;
  border-radius: 3px;
  border-color: $blue;
  outline: none;
}
@mixin style2 {
  color: $white;
  cursor: pointer;
  background-color: $blue;
  border-radius: 3px;
  border-color: $blue;
}
.custom-react-paginator {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  .pageNumber {
    width:15%;
    margin-top: 8px;
    font-weight: 400;
    color: $greyish-brown;
  }
  .react-paginate{
    width:70%;
    justify-content: center;
  }
  .rows-per-page {
    width:10%;
    margin-top:10px;
    color: $greyish-brown;
  }
  .react-select {
    width:6%;
    .css-d8oujb {
      display: none;
    }
  }
}
ul.react-paginate {
  [role=button] {
    cursor: pointer;
  }
  li {
    a {
      height: 32px;
      width: 32px;
      display: block;
      position: relative;
      float: left;
      padding: 5px 0;
      text-align:center;
      margin: 0 3px;
      line-height: 1.42857143;
      color: $greyish-brown;
      text-decoration: none;
      background-color: $white;
      border-radius: 3px;
      border: solid 0.7px #a7a7a7;
    }
    a:hover {
      @include style2;
    }
  }

  li.active {
    a {
      @include style1;
    }
    a:focus {
      @include style1;
    }
    a:hover {
      @include style1;
    }
  }
  li.disabled {
    pointer-events:none;
    opacity:0.6; 
  }
}